import React, { Fragment } from "react";
import { Link } from "gatsby";
import { LogoImage } from "@/data";
const ServiceSidebar = ({data}) => {
  return (
    <Fragment>
      <aside className="widget categories">
        <h3 className="widget_title">Services</h3>
        <div className="meipaly_categorie_widget">
          <ul>
            <li>
              <Link to="/website-development" >WEBSITE DEVELOPMENT</Link>
            </li>
            <li>
            <Link to="/mobile-development">MOBILE DEVELOPMENT</Link>
            </li>
            <li>
            <Link to="/uxui-design">UX/UI APP DESIGN</Link>
            </li>
            <li>
            <Link to="/digital-marketing">DIGITAL MARKETING</Link>
            </li>
          {/*   <li>
              <a href="#">SEO & Content Writting</a>
            </li> */}
          
          </ul>
        </div>
      </aside>
      <aside className="widget categories">
        <div className="meipaly_services_help ">
          <h4  /* className="text-center" */  >{data.sidebarTitle}</h4>
          <p  /* className="text-center"  */align="justify">
          {data.sidebarContent}
          </p>
          <br />
          <div style={{transform: 'translateY(40px)'}} className="d-flex ">
          <img style={{transform: 'translateY(-20px)'}} src={LogoImage.light}  alt="" />
          <h4 >{data.SidebarFooter}</h4>
      
        </div>
        </div>
      </aside>
    </Fragment>
  );
};

export default ServiceSidebar;
