import React from "react";
import { LogoImage, TunisiaLogo } from "@/data";
/* import  TN  from "@/images/flag-tunisia.png"; */


const Footer = () => {
  const { light } = LogoImage;
  return (
    <footer className="footer_1">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-sm-6 col-md-5">
            <aside className="widget aboutwidget">
            <div className=" d-flex">
                <img style={{transform: 'translateY(-10px)'}} src={LogoImage.light}  alt="" />
                
                <span style={{color: '#FFFFFF'}}   >REDEVIT DIGITAL SERVICES</span>
                </div> 
                <div>
              <p align="justify">
              WE PROVIDE DIGITAL SOLUTIONS, PROFESSIONAL CONSULTING AND SOFTWARE DEVELOPMENT SERVICES
              FOR YOUR DIGITAL SUCCESS.
               
              </p>
              </div>
            </aside>
          </div>
          <div className="col-lg-4 col-sm-4 col-md-4">
            <aside className="widget contact_widgets">
              <h3 className="widget_title">contact</h3>
              <span>
              <img style={{height:"20px", width:"20px", transform: 'translate(-5px, -2px)'}} src={TunisiaLogo.TN} />  <span style={{fontWeight: 'bold'}}>   Tunisia  </span>
              {/* <br /> */}
              <div>
              <i style={{transform: 'translateY(-3px)'}} className="fa fa-map-marker fa-lg" ></i>  20 Mars Jawhara, Sousse - 4000
                
              </div>
               
              <div>
              <i className="fa fa-phone" ></i>  : <a>+216 99 247 867</a>
              </div>
              <div>
              <i className="fa fa-envelope"></i> : <a>contact@redevit.tn</a>
              </div>
              </span>

             
            </aside>
          </div>
          <div className="col-lg-3 col-sm-2 col-md-3">
            <aside className="widget social_widget">
              <h3 className="widget_title">social</h3>
              <ul className="res-align">
                <li>
                  <a href="#">
                    <i className="fa fa-twitter"></i>Twitter
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/Redevit-100970634810186">
                    <i className="fa fa-facebook-square"></i>Facebook
                  </a>
                </li>
               {/*  <li>
                  <a href="#">
                    <i className="fa fa-youtube-play"></i>Youtube
                  </a>
                </li> */}
                <li>
                  <a href="https://www.linkedin.com/company/redevit">
                  <i className="fa fa-linkedin-square"></i>Linkedin            
                  </a>
                </li>
              </ul>
            </aside>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-center">
            <div className="copyright">
              © copyright {new Date().getFullYear()} - REDEVIT DIGITAL SERVICES - All rights reserved.
              {/* <a href="#">Layerdrops.com</a> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
