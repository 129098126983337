import React from "react";

const ServiceDetailsContent = ({data}) => {
  console.log('hdhdhd', data);
  return (
    <div className="serviceArea">
      <h2>{data.serviceTitle}</h2>
      <img src={data.serviceImage} alt="" />
      
      <p style={{whiteSpace: "pre-line"}}  align="justify">
      {data.serviceContent}
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={data.sectionImage} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>{data.sectionTitle}</h3>
       {/*    <p align="justify">
          {data[0].sectionContent}
          </p> */}
        
                          {data.lists.map(({ item }, index) => (
                              <ul key={index}>
                            <li style={{textTransform: "uppercase"}} key={index}>
                              <i className="fa fa-check-square"></i> {item}
                            </li>
                            </ul>
                          ))}
                       
         {/*  <ul>
            <li>
              <i className="fa fa-check-square"></i>Research beyond the business
              plan
            </li>
            <li>
              <i className="fa fa-check-square"></i>Marketing options and rates
            </li>
            <li>
              <i className="fa fa-check-square"></i>The ability to turnaround
              consulting
            </li>
            <li>
              <i className="fa fa-check-square"></i>Help companies into more
              profitable
            </li>
            <li>
              <i className="fa fa-check-square"></i>Customer engagement matters
            </li>
          </ul> */}
        </div>
      </div>
      <p style={{whiteSpace: "pre-line"}} align="justify">
        {data.paragraphe1}
      </p>
      <p style={{whiteSpace: "pre-line"}} align="justify">
      {data.paragraphe2}
      </p>
    </div>
  );
};

export default ServiceDetailsContent;
