import React, {useState} from "react";
import { Col, Container, Row } from "react-bootstrap";
import ServiceSidebar from "@/components/service-sidebar";
import ServiceDetailsContent from "@/components/service-details-content";


const ServiceDetails = ({ServicesDetails}) => {


  return (
    <section className="commonSection service_detail">
      <Container>
        <Row>
          <Col lg={8} md={12}>
       
            <ServiceDetailsContent data={ServicesDetails} />
          </Col>
          <Col lg={4} md={12} className="sidebar">
            <ServiceSidebar data={ServicesDetails} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ServiceDetails;
